export default function multiSelect(obj: any) {

    // This entire file needs to be refactored
    return {
        elementId: obj.elementId,
        elementWrapper: null,
        elementForm: null,
        options: [],
        selected: [],
        selectedElms: [],
        maxSelected: obj.maxSelected || 3,
        show: false,
        search: '',
        open() {
            this.show = true
            this.elementWrapper?.classList.add('active')
        },
        close() {
            this.show = false
            this.elementWrapper?.classList.remove('active')
        },
        toggle() {
            this.show = !this.show
            this.elementWrapper?.classList.toggle('active')
        },
        isOpen() {
            return this.show === true
        },
        
        // Initializing component 
        init() {
            // Get options from select element
            this.selectEl = document.getElementById(this.elementId) as HTMLSelectElement;
            this.elementWrapper = this.selectEl.closest('.multiselect__custom') as HTMLDivElement;
            this.elementForm = this.selectEl.closest('form') as HTMLFormElement;
            const options = this.selectEl.options;
            if (options) {
                for (let i = 0; i < options.length; i++) {
                    this.options.push({
                        value: options[i].value,
                        text: options[i].innerText,
                        search: options[i].dataset.search,
                        selected: Object.values(this.selected).includes(options[i].value)
                    });
                    if (this.options[i].selected) {
                        this.selectedElms.push(this.options[i])
                    }
                }
            }

            // Listen for changes on search input
            this.$watch("search", ((_e: any) => {
                this.options = []
                const elementID = document.getElementById(this.elementId) as HTMLSelectElement;
                const options = elementID.options;
                Object.values(options).filter((el) => {
                    var reg = new RegExp(this.search, 'gi');
                    return el.dataset.search?.match(reg)
                }).forEach((el) => {
                    let newel = {
                        value: el.value,
                        text: el.innerText,
                        search: el.dataset.search,
                        selected: Object.values(this.selected).includes(el.value)
                    }
                    this.options.push(newel);
                })
            }));

            // Get selected values from URL
            this.getQueryVariable(this.elementId)
        },
        // Deselect selected options
        async deselect() {
            this.selected = []
            this.selectedElms = []
            Object.keys(this.options).forEach((key) => {
                this.options[key].selected = false;
            })
            this.triggerAutoSubmit();
        },
        // Select given option
        async select(index, event) {
            let selectType = event.target.closest('.multiselect__custom').dataset.select;
            if (selectType == 'singleselect') {
                await this.deselect();
                this.updateOptions(index, event);
                this.close();
                return;
            }
            await this.updateOptions(index, event);
            this.triggerAutoSubmit();
        },
        async updateOptions(index, event) {
            if (!this.options[index].selected) {
                this.options[index].selected = true;
                this.options[index].element = event.target;
                this.selected.push(this.options[index].value);
                this.selectedElms.push(this.options[index]);
            } else {
                this.selected.splice(this.selected.lastIndexOf(index), 1);
                this.options[index].selected = false
                Object.keys(this.selectedElms).forEach((key) => {
                    if (this.selectedElms[key].value == this.options[index].value) {
                        setTimeout(() => {
                            this.selectedElms.splice(key, 1)
                        }, 100)
                    }
                })
            }
        },
        // remove from selected option
        async remove(index, option, event) {
            // Remove from selected values in visible multiselect
            this.selectedElms.splice(index, 1)
            // Remove from selected values in hidden input
            await this.remove_options(option);            
            this.triggerAutoSubmit();          
        },
        async remove_options(option) {
            Object.keys(this.options).forEach((key) => {
                if (this.options[key].value == option.value) {
                    this.options[key].selected = false;
                    Object.keys(this.selected).forEach((skey) => {
                        if (this.selected[skey] == option.value) {
                            this.selected.splice(skey, 1);
                        }
                    })
                }
            })
        },
        // filter out selected elements
        selectedElements() {
            return this.options.filter(op => op.selected === true)
        },
        // get selected values
        selectedValues() {
            return this.options.filter(op => op.selected === true).map(el => el.value)
        },
        updateSelectedValues(event) {
            this.getQueryVariable(this.elementId)
        },
        // Get query string value
        getQueryVariable(variable) {
            const urlParams = new URLSearchParams(window.location.search);
            const selected = urlParams.get(variable);
            if (selected) {
                const selectedValues = selected.split(',');
                // match selectedValues with this.options and return index
                Object.keys(this.options).forEach((key) => {
                    if (selectedValues.includes(this.options[key].value)) {
                        this.options[key].selected = true;
                        this.selected.push(this.options[key].value);
                        this.selectedElms.push(this.options[key]);
                    }
                })  
            } else {
                this.deselect();
            }
        },
        triggerAutoSubmit() {                
            console.log('triggerAutoSubmit')
            if (this.elementForm?.getAttribute('data-auto-submit') === 'true') {
                this.elementForm.dispatchEvent(new Event('change'));
            }
        }
    }
}