import { updateURLParams, urlParamsToFormData, getFormData, populateFormHTML, clearFormData, getHeaderHeight } from '../util/formHelpers'

export default function () {
	const postFilters = document.querySelectorAll('.post-filter')
	const filterEndpoint = '/wp-json/hmw-post-filter/v1/get_filter'
	if (postFilters) {
		postFilters.forEach((postFilter) => {
			new Post_Filter(postFilter, filterEndpoint)
		})
	}
}

export const Post_Filter = class {

	// Type definitions
	apiURL: string
	HTMLelement: HTMLElement
	HTMLloader: HTMLElement | null
	HTMLfilter: HTMLFormElement | null
    HTMLautosubmit: string
    HTMLpage: HTMLInputElement | null
	HTMLformData: any
	HTMLresults: HTMLElement | null
	HTMLquery: string | null
	results: any 

	// Constructor
	constructor(element, apiURL) {
		// Setup variables
		this.apiURL = apiURL
		this.HTMLelement = element
		this.HTMLloader = this.HTMLelement?.querySelector('.post-filter__loader')
		this.HTMLfilter = this.HTMLelement?.querySelector('form.post-filter__form') || null 
		this.HTMLautosubmit = (this.HTMLfilter?.getAttribute('data-auto-submit') === 'true') ? 'change' : 'submit'
        this.HTMLpage = this.HTMLfilter?.querySelector('input[name="filter-page"]') || null
		this.HTMLformData = {}
		this.HTMLresults = this.HTMLelement?.querySelector('.post-filter__results') || null
		this.HTMLquery = this.HTMLresults?.getAttribute('data-query') || null
		
		// Setup event listeners
		this.handle_events();

		// Setup initial state - Get search params from URL and update form
        let searchParams = urlParamsToFormData();

        // If searchParams.entries() isn't empty
		if (searchParams.entries().next().value) {	
			// Show loader on initial load
			this.show_loader()

            // Loop through search params and update form - split comma separated values
			populateFormHTML(searchParams, [this.HTMLfilter])
        }

        // Reset HTMLquery and submit form to trigger API call
        this.update_HTMLquery('reset', 1).then(() => {
            // Trigger change event on form to update results
            this.HTMLfilter?.dispatchEvent(new Event(this.HTMLautosubmit));
        });

        return;
	}


	// Fetch results from API endpoint
	async _fetch_results(endpoint) {
		let res = await fetch(endpoint),
			{ html } = await res.json()

		// Append / replace results from HTML
		this.render_new_results(html)

		// Hide loader
		this.hide_loader()

		// Scroll to top of results
		// this.scroll_to_element(this.HTMLresults)
	}
	

	// Display results from API in HTML
	async render_new_results(html) {
		if (this.HTMLresults) {
            this.HTMLresults.innerHTML = html
		}

		// Temporary (for offset) - Trigger resize after 100ms
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 100)	
	}


	scroll_to_element(element) {
		// Get offset of this.HTMLresults from top of page
		if (element) {
			const offset = element.getBoundingClientRect().top + window.scrollY - getHeaderHeight() || 0
            window.scroll({
                top: offset,
                left: 0,
                behavior: 'smooth'
            });
		}
	}


	// Update HTMLquery with 'update' or 'reset' state - For pagination
	async update_HTMLquery(state, page) {
		if (!this.HTMLquery) return
			
		// Update HTMLquery based on state
		if (state === 'update') {
            if (this.HTMLpage) {
			    this.HTMLpage.value = page
            }
		} else if (state === 'reset') {
            // this.HTMLpage?.setAttribute('value', '1')
        }
	}


	// Clear results from HTML
	async clear_results() {
		if (this.HTMLresults) {
			this.HTMLresults.innerHTML = ''
		}
	}


	async clear_form(forms = [this.HTMLfilter]) {
		clearFormData(forms)

        // Get form data
        let formData = getFormData(this.HTMLfilter);

        formData = { ...formData };

        // Update HTMLformData with formData
        this.HTMLformData = formData

        // Update URL with new query string containing form data
        let URLParams = updateURLParams(formData) || '?';

        if (URLParams === '?') {
            // If no form data, remove query string from URL
            history.pushState(null, '', window.location.pathname);			
        }
	}

	// Show/ hide loader
	async show_loader() {
		this.HTMLloader?.classList.remove('d-none')
	}
	async hide_loader() {
		this.HTMLloader?.classList.add('d-none')
	}

	// Setup event listeners
	handle_events() {
		
		// Add event listener to HTML form to trigger API call / filtering
		this.HTMLfilter?.addEventListener(this.HTMLautosubmit, async (e) => {
			e.preventDefault()

			// Show loader
            this.show_loader()

			// Get form data
			let formData = getFormData(this.HTMLfilter);

			formData = { ...formData };

            // Check if HTMLformData is the same as formData - If not, reset HTMLquery - Used for pagination resetting when form data changes
			if (Object.values(this.HTMLformData).length && (JSON.stringify(this.HTMLformData) != JSON.stringify(formData))) {
				await this.update_HTMLquery('reset', 1)
			}

			// Update HTMLformData with formData
			this.HTMLformData = formData

			// Update URL with new query string containing form data
			let URLParams = updateURLParams(formData) || '?';

			if (URLParams === '?') {
				// If no form data, remove query string from URL
				history.pushState(null, '', window.location.pathname);			
			}

			// Trigger API call
			this._fetch_results(`${this.apiURL}/${URLParams}&post_query=${this.HTMLquery}`)
		})

		// Prevent enter key from submitting form
		this.HTMLfilter?.addEventListener('keypress', (e) => {
			if( e.keyCode == 13 ) {
				e.preventDefault()
			}
		})

		// Event listener for results container - due to contents being replaced with each API call
		this.HTMLresults?.addEventListener('click', async (e) => {
			const target = e.target as HTMLElement
			
			if (target.classList.contains('page-numbers') && !target.classList.contains('current') && !target.classList.contains('dots')) {
				e.preventDefault()

				// Show loader
				this.show_loader()

				// Get data-page from target
				let page = target.getAttribute('data-page') || 1

				// Update HTMLquery - for pagination
				await this.update_HTMLquery('update', page)

				// Dispatch change event to trigger API call
				this.HTMLfilter?.dispatchEvent(new Event(this.HTMLautosubmit));

				// Dispatch change event to trigger API call
				this.HTMLform?.dispatchEvent(new Event('submit', { cancelable: true }));
			}

            // If target was clear button 
            if (target.classList.contains('post-filter__clear')) {
                e.preventDefault()

                // Clear form
                await this.clear_form([this.HTMLfilter])

                // Clear results from HTML
                this.clear_results()

                // Reset HTMLquery - offset and paged
                await this.update_HTMLquery('reset', 1)

                // Dispatch change event to trigger API call
                this.HTMLfilter?.dispatchEvent(new Event(this.HTMLautosubmit));
            }
		})
	}
}