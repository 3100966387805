import "../scss/frontend.scss";

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import multiSelect from './components/alpine/multiSelect'
import PostFilter from "./components/PostFilter";

declare global {
	interface Window {
        Alpine: any;
	}
}

Alpine.data("MultiSelect", multiSelect);
Alpine.plugin(focus)
Alpine.start()

window.addEventListener('DOMContentLoaded', function() {
	PostFilter();
});