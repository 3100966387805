// import { Collapse } from '../bootstrap'

// Get URL params
export function getURLParams(params = {}) {
    const url = new URL(window.location.href);
    const updatedSearchParams = new URLSearchParams();

    // Loop through object params and update search params
    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => updatedSearchParams.append(key, item));
        } else {
            updatedSearchParams.append(key, value as string);
        }
    });

    // Merge duplicate params together (e.g. multiple categories) and comma separate and remove empty params
    const mergedSearchParams = Array.from(updatedSearchParams.entries()).reduce(
        (mergedParams, [key, value]) => {
            if (value === '' || value == null || value === 'undefined') {
                return mergedParams;
            }
            mergedParams.set(key, mergedParams.has(key) ? `${mergedParams.get(key)},${value}` : value);
            return mergedParams;
        },
        new URLSearchParams()
    );

    // Remove empty params
    Array.from(mergedSearchParams.entries()).forEach(([key, value]) => {
        if (value === '' || value == null || value === 'undefined') {
            mergedSearchParams.delete(key);
        }
    });

    // Build a new URL object with the updated search parameters
    const newUrl = new URL(url);

    // newUrl.search = mergedSearchParams.toString().replace(/%5B/g, '[').replace(/%5D/g, ']').replace(/%2C/g, ',');
    newUrl.search = decodeURIComponent(mergedSearchParams.toString());

    return newUrl;
}

// Update URL params
export function updateURLParams(params) {
    let newUrl = getURLParams(params);

    // // If URL contains ?page=1, remove it
    // if (newUrl.search.match(/page=1/)) {
    //     newUrl.search = newUrl.search.replace(/page=1&?/, '');
    // }

    // Strip any trailing & from the URL
    if (newUrl.search.endsWith('&')) {
        newUrl.search = newUrl.search.slice(0, -1);
    }

    // Update the URL and push it to the history
    window.history.pushState({}, '', newUrl);

    return newUrl.search;
}

// Convert URL query string to FormData object
export function urlParamsToFormData() {
    // check if the browser is firefox
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
        // If the browser is Firefox, return null
        return null;
    }
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const formData = new FormData();

    for (const [key, value] of searchParams.entries()) {
        formData.append(key, value);
    }

    return formData;
}

export function getFormData(form) {
    // Get data from posted form
    const formData = new FormData(form)
    const data = {};
    for (const [key, value] of formData.entries()) {
        if (formData.getAll(key).length > 1) {
            data[key] = formData.getAll(key);
        } else {
            data[key] = value;
        }
    }
    return data;
}

export function getInputType(input) {
    const tagName = input.tagName.toLowerCase();
    if (tagName === 'select') {
        return 'select';
    } else if (tagName === 'input') {
        const inputType = input.type.toLowerCase();
        if (inputType === 'checkbox' || inputType === 'radio') {
            return 'checkbox';
        } else {
            return 'text';
        }
    } else if (tagName === 'textarea') {
        return 'textarea';
    } else {
        return 'text';
    }
}

export function populateFormHTML(searchParams: FormData, HTMLforms: Array<HTMLFormElement | null>): Promise<void> {
    return new Promise((resolve) => {
        HTMLforms?.forEach(HTMLform => {
            for (const [key, value] of searchParams.entries()) {
                if (typeof value === 'string') {
                    // Find form element and type based on key
                    const input = HTMLform?.querySelector(`[name="${key}"]`) as HTMLInputElement || null

                    if (input) {
                        const type = getInputType(input);

                        // If input type is checkbox or radio, split based on , and loop and check
                        if (type === 'checkbox') {
                            value.split(',').forEach(item => {
                                if (value == '' || value == null || value == 'undefined') { 
                                    return;
                                }
                                const inputOption = HTMLform?.querySelector(`[name="${key}"][value="${item}"]`) as HTMLInputElement || null;
                                if (inputOption) {
                                    inputOption.checked = true;
                                }
                                // Check if all checkboxes of the sanem name are checked (minus the checkbox with data-all="true") and check the checkbox with data-all="true"
                                const allCheckbox = HTMLform?.querySelector(`[name="${key}"][data-all="true"]`) as HTMLInputElement || null;
                                if (allCheckbox) {
                                    const siblingCheckboxes = HTMLform?.querySelectorAll(`[name="${key}"]:not([data-all="true"])`) as NodeListOf<HTMLInputElement> || null;
                                    const siblings = Array.from(siblingCheckboxes) as Array<HTMLInputElement> 
                                    allCheckbox.checked = siblings.every(sibling => sibling.checked);
                                }
                                openGrandparent(inputOption);                    
                            })
                        }
                        // If input type is select, split based on , and loop and select
                        else if (type === 'select') {
                            value.split(',').forEach(item => {
                                if (value == '' || value == null || value == 'undefined') { 
                                    return;
                                }
                                const inputOption = HTMLform?.querySelector(`[name="${key}"] option[value="${item}"]`) as HTMLOptionElement || null;
                                if (inputOption) {
                                    inputOption.selected = true;
                                }
                                openGrandparent(inputOption);                    
                            })
                        }
                        // Otherwise, update value
                        else {
                            if (value == '' || value == null || value == 'undefined') { 
                                return;
                            }
                            input.value = value;
                        }

                        openGrandparent(input);  
                    }
                }
            }
        })
        resolve();

        function openGrandparent(input) {
            const grandparentUL = input.parentElement?.parentElement;
            const collapse = grandparentUL?.classList.contains('collapse') ? grandparentUL : null;
            if (collapse) {
                new Collapse(collapse).show();
            }
        }
    })
}

export function populateFormRefineBy(HTMLelement: HTMLElement, HTMLform: HTMLFormElement) {
    // Get form data from refine form and inject into div with class refineby - Temporary until multi-select is refactored
    const formData = getFormData(HTMLform);
    if (formData) {
        let formDataArr = [] as any;
        Object.entries(formData).forEach(([key, value]) => {
            if (!value) return;
            // If value is an array, loop through and get the data-label
            if (Array.isArray(value)) {
                // Strip empty values from array
                value?.forEach((item) => {
                    if (item == '' || item == null || item == 'undefined') { 
                        return;
                    }
                    const input = HTMLform.querySelector(`[name="${key}"][value="${item}"]`) as HTMLInputElement || null;
                    const label = input?.getAttribute('data-label') || item;
                    formDataArr.push({ label });
                })
                return;
            } else {
                const input = HTMLform.querySelector(`[name="${key}"][value="${value}"]`) as HTMLInputElement || null;
                const label = input?.getAttribute('data-label') || value;
                formDataArr.push({ label });
            }
        });

        if (!formDataArr.length) return;

        let formDataHTML = '<span class="refineby__title">Currently filtering by:</span> ';
        formDataArr.forEach((el) => {
            const comma = formDataArr.indexOf(el) < formDataArr.length - 1 ? ', ' : '';
            formDataHTML += `<span class="refineby__item fw-bold">${el.label}</span>` + comma;
        })

        let refineby = HTMLelement?.querySelector('.post-filter__refineby') as HTMLElement || null;
        refineby.innerHTML = formDataHTML
    }
}

export function clearFormData(HTMLforms: Array<HTMLFormElement | null>) {
    HTMLforms?.forEach(HTMLform => {
        // Loop through form elements and clear values
        HTMLform?.querySelectorAll('input').forEach(item => {
            const input = item as HTMLInputElement;
            if (input.type === 'checkbox' || input.type === 'radio') {
                input.checked = false;
            } else {
                input.value = '';
            }
            input.dispatchEvent(new Event('change'));
        })
        HTMLform?.querySelectorAll('select').forEach(item => {
            const input = item as HTMLSelectElement;
            input.selectedIndex = 0;
            input.dispatchEvent(new Event('change'));
        })
        HTMLform?.querySelectorAll('textarea').forEach(item => {
            const input = item as HTMLTextAreaElement;
            input.value = '';
            input.dispatchEvent(new Event('change'));
        })
    })
}

export function getHeaderHeight() {
    const header = document?.querySelector('header#wrapper-navbar') as HTMLElement,
          header_style = window.getComputedStyle(header) as CSSStyleDeclaration,
          headerheight = parseInt(header_style.getPropertyValue('--headerheight')) || 0

    return headerheight
}